/* classe per nascondere elementi variabili quando si testa con puppeteer */
body.puppeteer .puppeteer-hidden, body.puppeteer div[class*='-loadingIndicator'] {
    visibility: hidden !important;
}

body.puppeteer * {
    transition-duration: 0s !important;
    transition-delay: 0s !important;
}

body.puppeteer .fade {
    opacity: 1 !important;
}


/* cose generiche relative al tema oppure piccoli helper*/

.page-wrapper {
    min-height: calc(100vh - 70px); /* navbar height! */
}

#root .page-titles {
    padding-bottom: 0;
}

.food-secondary .card {
    margin-bottom: 0;
}

.full-height {
    height: 100%;
}

.full-width {
    width: 100%;
}

.hidden {
    display: none;
}

.margin-right {
    margin-right: 5px;
}

.margin-top {
    margin-top: 10px;
}

.margin-left {
    margin-left: 10px;
}

.margin-bottom {
    margin-bottom: 20px;
}

.form-text {
    font-size: 1em;
    color: #444;
}

.form-group .form-text {
    font-size: .9em;
}

.statistic {
    text-align: center;
    font-size: 4rem;
}

.usability-note {
    color: #999;
    font-style: italic;
}

.usability-note.error {
    color: #f62d51;
}

.card-footer {
    background-color: transparent;
}

/* sidebar */

aside.left-sidebar {
    z-index: 60;
}

#logo {
    margin-top: -80px;
    margin-bottom: 30px;
    width: 90%;
}

aside.left-sidebar li a {
    font-size: 16px;
    font-weight: 400;
}

aside.left-sidebar ul.collapse li a {
    font-size: 15px;
    font-weight: 300;
}

/* tabelle */

.table-cell {
    padding: .75rem .75rem .75rem 0;
}

.table-row {
    border-top: 1px solid #eceeef;
}

.table-row:first-child {
    border-top: none;
}

/* -- */

/* login */

.login-register {
    background-image: url("template/background.png");
    padding: 5% 0;
}

.valign-helper {
    display:flex;
    justify-content:center;
    align-items:center;
    height: 100%;
}

.login-logo {
    width: 250px;
}

/* -- */

/* navbar */

#root .topbar .top-navbar .navbar-header {
    line-height: 70px;
    height: 70px;
}

#root header.topbar {
    background: #ed1c24;
}

/* -- */

/* editor wysiwyg */
.form-group .public-DraftEditor-content {
    height: 200px;
}

/* media modal */

.media-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.media-container .thumb {
    margin: auto;
}

.media-container .media-description {
    max-width: 200px;
    overflow-wrap: break-word;
}

.media-container .card-body {
    padding: 0;
}

.media-container .card {
    margin: 10px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    width: 200px;
}

.media-container .card-text {
    padding: 10px;
}

.media-container .media-actions {
    width: 100%;
    text-align: right;
}

.selected .thumb {
    border: 4px solid #7460ee;
    background: no-repeat center;
    background-size: contain;
}

.image-hover-container {
    overflow: hidden;
    cursor: pointer;
}

/* -- */

/* vari casi albero */

.single-tree .rct-checkbox {
    display: none;
}
.single-tree .rct-node-leaf .rct-checkbox {
    display: inline-block;
}

/* -- */

/* paginator */
.list-actions {
    text-align: right;
}

.paginator, .column-manager {
    display: inline-block;
}

.column-manager {
    margin-right: 10px;
}

.paginator select {
    width: 100px;
    margin-right: 25px;
    margin-top: 25px;
}

.secondary .paginator {
    margin-top: 0;
}

/* filter */

.filters .form-inline .form-group {
    overflow: visible;
}

.filters h4 {
    text-transform: uppercase;
}

.react-datepicker__input-container, .filters .form-inline .form-control, .filter .Select {
    width: 100%;
}

.form-group.compact {
    width: 100%;
}

.form-group.compact .Select-placeholder {
}

.form-group.compact .col {
    padding: 0;
}

/* -- */

/* tabs */

.nav-link {
    font-weight: bold;
    cursor: pointer;
    background-color: #d2d7d8;
    margin-right: 3px;
}

.nav.nav-tabs {
    background-color: #f2f7f8;
    padding-left: 10px;
}

/* -- */

/* select */
.Select-menu-outer {
    z-index: 100;
}

.compact .Select-control, .compact .Select-menu-outer, .compact .form-text, .spaced-left {
    margin-left: 15px;
}
/* -- */

/* graphiql e voyager */
.graphiql .graphiql-container {
    height: calc(100vh - 130px);
    /*margin-left: -30px;*/
    /*margin-top: -20px;*/
    /*width: calc(100% + 60px);*/
    /*margin-bottom: -25px;*/
}

.graphiql > .container-fluid {
    padding: 0;
}

/* -- */

/* checkbox */

.form-group input.form-check-input {
    margin-left: 0;
}

.col.checkbox-container {
    padding-top: calc(0.5rem - 1px * 2);
}

/* -- */

/* images with cover */

.image-container {
    position: relative;
    margin-bottom: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-small {
    max-height: 200px;
    max-width: 200px;
    display: inline-block;
}

.image-cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}

/* -- */

/* Fix problems with sweetalert and renew modal overlapping */
.renew-modal-wrapper .modal {
    z-index: 10003;
}

.renew-modal-wrapper .modal-backdrop {
    z-index: 10002;
}

.sweet-alert {
    z-index: 10001;
}

/* -- */

/* GraphiQL nei campi di modifica query */

.query-field .graphiql-container {
    min-height: 300px;
}

.query-field .graphiql-container .topBarWrap,
.query-field .graphiql-container .variable-editor,
.query-field .graphiql-container .docExplorerWrap,
.query-field .graphiql-container .resultWrap {
    display: none;
}
/* -- */

/* Set status */
.status-setter {
    width: 200px;
}

.status-setter .Select-control, .status-setter .Select-menu-outer {
    margin-left: 0;
}
/* -- */

/* Info tooltip */
.question-icon {
    opacity: .5;
}

.question-icon:hover {
    opacity: 1;
    color: #f62d51;
}
/* -- */


/* Entity List IField */
.form-group .table thead {
    font-weight: bold;
}
/* -- */

.card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
}

ul.nav.nav-tabs {
    /*overflow-x: auto;
    overflow-y: hidden;*/
}

.actions-bottom {
    margin-bottom: 25px;
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #eceeef;
}

.login-container > div {
    border: 1px solid #d0d1d2;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.action-top-right {
    position: absolute;
    top: 0;
    right: 10px;
}

.padding-top-20 {
    padding-top: 20px;
}

#pricing-image {
    width: 100%;
}

.capolettera {
    float: left;
    margin: 5px;
    font-size: 200%;
}

.vademecum-result {
    font-size: 150%;
    text-align: center;
}

.award-shortcut  a {
    margin-top: .7rem;
    margin-bottom: .7rem;
}

.award-shortcut:last-child {
    margin-bottom: 0;
}

.checklist-info {
    text-align: center;
}

.checklist-action {
    margin: .5rem;
}

.checklist-action-title {
    margin-top: 1rem;
}

.award-card-home, .award-card-home-2, .platform-home-card {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.award-card-home-2 {
    justify-content: space-between;
    align-items: flex-end;
}

.award-card-home-3 {
    margin-top: 2rem;
}

.award-card-home > img {
    width: 15%;
    margin-right: 1rem;
}

.award-card-home h2 {
    margin-bottom: 0;
}

.award-card-daytilclose {
    text-align: center;
    margin-top: .5rem;
}

.award-card-daytilclose > span {
    font-size: 2em;
    display: block;
    font-weight: bold;
}

.platform-home-card > img {
    width: 45%;
}

.platform-home-card {
    /*background-color: #cce5ff;*/
}

.card-info {
    border: 1px solid #b8daff;
    background-color: #cce5ff;
}

.invalid-feedback {
    color: #f62d51;
    font-weight: 500;
    margin-top: .5rem;
}

.nav-tabs {
    flex-wrap: wrap;
}

.nav-tabs li {
    margin-top: 3px;
}

.ql-container {
    height: 300px;
}

.full-width {
    width: 100%;
    padding: 0;
    margin: 0;
}

.grey {
    color: rgb(128, 128, 128);
}

.boolean-select-value {
    font-weight: 600;
}

.boolean-select-value.yes {
    color: #00a650;
}

.boolean-select-value.no {
    color: #ed1c24;
}

.wordpress-post-image-preview {
    width: 100%
}

.card.forced-border {
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.is-invalid.form-control {
    border-color: #f62d51;
}

.input-border.is-invalid > .__control {
    border: 1px solid #f62d51;
}

.group-border-left {
    padding-top: .5rem;
    border-left: 4px solid #e0e0e0;
}

.group-border-left.danger {
    border-color: #f62d51;
}

.invalid-feedback {
    display: block !important;
    font-size: 1em;
}

.react-datepicker-wrapper {
    width: 100%;
}


.fa {

}


.fa:before {
    display: inline-block;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.rotation:before {
    display: inline-block;
    animation: rotation 2s infinite linear;
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animated.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.animated.delay-1s {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

.animated.delay-2s {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}

.animated.delay-3s {
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
}

.animated.delay-4s {
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
}

.animated.delay-5s {
    -webkit-animation-delay: 5s;
    animation-delay: 5s;
}

.animated.fast {
    -webkit-animation-duration: 800ms;
    animation-duration: 800ms;
}

.animated.faster {
    -webkit-animation-duration: 200ms;
    animation-duration: 200ms;
}

.animated.slow {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
}

.animated.slower {
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
}


@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 15%, 0);
        transform: translate3d(0, 15%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 15%, 0);
        transform: translate3d(0, 15%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

.table {
    color: #54667a
}

.table thead th {
    text-transform: uppercase;
    font-weight: 700;
    padding-bottom: 1em;
}

.table td {
    padding: .4em;
    vertical-align: middle;
}

.table tbody tr:first-child td {
    border-top: none;
}

.table tbody tr:hover {
    background-color: #f3f1f1;
}


[color="danger"] .ql-toolbar.ql-snow, [color="danger"] .ql-container.ql-snow {
    border-color: red;
}
